<template>
  <div class="content">
    <div class="aside">
      <div class="Module-title">
        <div class="title">我的工作台</div>
      </div>
<!--      <div class="title">平面设计</div>-->
<!--      <ul class="nev-menu-ul">-->
<!--        <router-link to="/OwnWorkbench/OwnDesign" tag="li">-->
<!--          <div class="item-content">-->
<!--            <div class="pic">-->
<!--              <img src="@/assets/img/OwnWorkbench/bar-1.png" />-->
<!--            </div>-->
<!--            <div class="name">我的作品</div>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </ul>-->
<!--      <ul class="nev-menu-ul">-->
<!--        <router-link to="/OwnWorkbench/OwnTemplate" tag="li">-->
<!--          <div class="item-content">-->
<!--            <div class="pic">-->
<!--              <img src="@/assets/img/OwnWorkbench/bar-2.png" />-->
<!--            </div>-->
<!--            <div class="name">我的模板</div>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </ul>-->
<!--      <ul class="nev-menu-ul">-->
<!--        <router-link to="/OwnWorkbench/OwnMaterial" tag="li">-->
<!--          <div class="item-content">-->
<!--            <div class="pic">-->
<!--              <img src="@/assets/img/OwnWorkbench/bar-3.png" />-->
<!--            </div>-->
<!--            <div class="name">我的素材</div>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </ul>-->
<!--      <div class="space"></div>-->
      <div class="title">营销活动</div>
      <ul class="nev-menu-ul">
        <router-link to="/OwnWorkbench/OwnActivity" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/OwnWorkbench/bar-4.png" />
            </div>
            <div class="name">我的活动</div>
          </div>
        </router-link>
      </ul>
      <ul class="nev-menu-ul">
        <router-link to="/OwnWorkbench/CollectedTemp" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/OwnWorkbench/bar-5.png" />
            </div>
            <div class="name">我的模板</div>
          </div>
        </router-link>
      </ul>
      <ul class="nev-menu-ul">
        <router-link to="/OwnWorkbench/PrizeResources" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/OwnWorkbench/bar-5.png" />
            </div>
            <div class="name">奖品资源</div>
          </div>
        </router-link>
      </ul>
      <div class="space"></div>
      <ul class="nev-menu-ul">
        <router-link to="/OwnWorkbench/QRManagement" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/OwnWorkbench/bar-6.png" />
            </div>
            <div class="name">二维码管理</div>
          </div>
        </router-link>
      </ul>
      <ul class="nev-menu-ul">
        <router-link to="/OwnWorkbench/form" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/OwnWorkbench/bar-6.png" />
            </div>
            <div class="name">表单二维码</div>
          </div>
        </router-link>
      </ul>
      <!-- <div style="height:80px"></div>
              <div class='title'>基础信息</div>
              <nev-menu-home :nevdata="designTap"> </nev-menu-home>
              <div class='space'></div>
               <nev-menu-home :nevdata="designTap"> </nev-menu-home> -->
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

// import NevMenuHome from '@/components/NevMenuHome.vue'

export default {
  data () {
    return {
      // designTap:[
      //   {
      //     name:'印刷订单',
      //     img:require("@/assets/img/shoppingcart.png")
      // },
      // {
      //     name:'帮我下单',
      //     img:require("@/assets/img/shoppingcart.png")
      // }]
    }
  },
  components: {
    // NevMenuHome
  }
}
</script>

<style lang="scss" scoped>
.nev-menu-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  .router-link-active {
    background-color: #e7e7ef;
    position: relative;
  }
  .router-link-active::after {
    content: " ";
    display: block;
    width: 5px;
    height: 30px;
    background: black;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  li {
    height: 46px;
    line-height: 46px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    .item-content {
      width: 218px;
      height: 46px;
      display: flex;
      justify-content: center;
      &:hover {
        background-color: #f1f2f4;
      }
      .pic {
        width: 20%;
      }
      .name {
        width: 60%;
        text-align: left;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #33383e;
      }
    }
  }
}

.content {
  /*width: 1920px;*/

  margin: auto;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(238, 238, 238, 0.699);
  .aside {
    width: 250px;
    height: 100%;
    padding-top: 15px;
    float: left;
    background-color: white;
    position: relative;
    .Module-title {
      height: 70px;
      border-bottom: 1px solid rgb(219, 219, 219);
      margin-bottom: 20px;
      .title {
        line-height: 70px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #939ba6;
        img {
          margin-right: 10px;
        }
      }
    }
    .title {
      padding-left: 32px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #939ba6;
      margin-bottom: 10px;
    }
    .space {
      height: 1px;
      background: rgb(218, 218, 218);
      width: 166px;

      margin: 30px 0 30px 30px;
    }
  }
  .main {
    /*float: right;*/
    /*overflow-x: hidden;*/
    /*width: 1650px;*/
    width: calc(100% - 250px);
    height: 100%;
    background-color: white;
    margin-left: 250px;
  }
}
</style>
